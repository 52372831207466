.card_style {
    height: 275px; 
    /* background-image: url('/dashboard/images/card_background.png') !important;  */
    border-bottom-left-radius: 50px; 
    border-bottom-right-radius: 50px;
}

.card_top {
    padding-top: 60px !important;
}

.h4_color {
    color: #e5e5e5 !important;
}

.service_icon {
    color: #ffffff !important; 
    background-color: #9e9e9e
}

.service_test {
    color: #1f1f1f; 
    font-size: 12px !important; 
    font-weight: 500 !important;
}

.network_style {
    background-color: rgb(69 122 193);    
    color: #ffffff !important;
}

.network_txt {
    background-color: #ff6d0d !important;
    font-size: 11px !important;
}

.plan_txt {
    color: #1f1f1f; 
    font-size: 12px !important; 
    font-weight: 500 !important;
}

.amount_icon {
    color: #ffffff !important; 
    background-color: #ff6d0d;
}

.amount_txt {
    background-color: #4a89dc !important; 
    font-size: 11px !important
}

.invoice_card {
    margin-left: 14px; 
    margin-right: 14px; 
    box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px; 
    border-radius: 25px !important; 
    margin-bottom: -14px;
}

.phone_number_icon {
    color: #ffffff !important; 
    background-color: #009688;
}

.phone_number_txt {
    color: #1f1f1f; 
    font-size: 12px !important; 
    font-weight: 500 !important;
}

.renewal_icon {
    color: #ffffff !important; 
    background-color: #F6BB42
}

.proceedBtn {
    background-image: linear-gradient(to bottom,#656D78,#434A54)!important; 
    color: #ffffff;
}

.service_text {
    color: #1f1f1f; 
    font-size: 12px !important; 
    font-weight: 500 !important
  }

.customer_name_icon {
    color: #ffffff !important; 
    background-color: #3f51b5;
}
