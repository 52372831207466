.Vcard {
    margin: 0px 15px 8px 15px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 1px solid #23639a !important;
    border-top: 1px solid #990b81 !important;
  }
  
  .VAcctTxt {
      border-color: #9c27b0 !important;
      color: #9c27b0 !important;
  }
  
  .VFundTxt {
      line-height: 15px !important;
      color: #ff001a;
  }
  
  .VBank {
      color: #f3d053 !important;
  }