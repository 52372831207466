.Vcard {
  margin: 0px 15px 8px 15px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 1px solid #23639a !important;
  border-top: 1px solid #990b81 !important;
}

.VAcctTxt {
    border-color: #006066 !important;
    color: #006066 !important;
}

.VFundTxt {
    line-height: 15px !important;
    color: #ff001a;
}

.VBank {
    color: #ff9800 !important;
}