.page_content {
    padding-bottom: 30px !important;
}

.data_card {
    height: 275px;
    /* background-image: url("/dashboard/images/adalia-botha-unsplash.jpg") !important;  */
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.card_border {
    border: 1px solid #c9d2ff !important
}

.data_bal_card {
    border-top: 1px solid #c9d2ff !important;
    border-bottom: none;
}

.data_bal_txt {
    font-family: 'Uchen', serif !important;
}

.pt_65{
    padding-top: 65px !important;
}

.txt_font_color{
    color: #e5e5e5 !important;
}

.pt_30 {
    padding-bottom: 10px !important;
}

.pt_13 {
    padding-bottom: 13px !important;
}

.description_txt{
    margin-bottom: 8px;
    line-height: 22px !important;
    font-size: 12px;
    font-style: italic;
}

.pasteMyNumber {
    border-color: #607d8b !important;
    color: #ea3023 !important;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-bottom: 8px !important;
}

.txtarea {
    box-shadow: 0 4px 10px 0 rgba(0,0,0,.1) !important;
    height: 100px !important;
}

.check_font {
    background-color: red !important
}

.user_font {
    background-color: green !important
}

.proceedBtn {
    background-image: linear-gradient(to bottom,#656D78,#434A54) !important;
    color: #ffffff;
}

.data_card_2 {
    border-radius: 25px !important;
    margin-left: 15px;
    margin-right: 15px;
    box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
    margin-bottom: 0px;
}

.left_border {
    /* border-left: 2px solid #e5e5e5;
    padding-left: 600px; */
  }

  /* @media only screen and (max-width: 768px) {
        .left_border {
            border-left: 1px solid #e5e5e5;
            padding-left: 70px;
        } 
    } */

  .bg_red {
    background-color: red !important;
  }

  .bg_green {
    background-color: green !important;
  }

  .icon_font_color {
    color: #000c !important
  }
  
.topCardFont {
    /* font-family: 'Philosopher', sans-serif !important; */
}

.profile_card_border {
    border-bottom: 2px solid #4a89dc !important
}



.kyc_card_border {
    border-top: 2px solid rebeccapurple !important;
    border-bottom: 2px solid rebeccapurple !important;
  }