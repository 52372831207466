.CardPaddingRight {
  padding-right: 3px !important;
}

.CardPaddingLeft {
  padding-left: 3px !important;
}

.serviceGradient {
  background: linear-gradient(
    to bottom,
    rgb(0 0 0 / 60%) 0%,
    rgb(0 0 0 / 64%) 20%,
    rgb(36 36 36 / 62%) 30%,
    rgba(0, 0, 0, 0.8) 70%,
    black 100%
  ) !important;
}

.electricityCard {
  margin: -18px 15px 30px 15px !important;
  border-top: 2px solid #fe7704 !important;
}

.electricityContentMargin {
  margin-top: 10px !important;
}


.bulkSMSCard {
  margin: -18px 15px 32px 15px !important;
  /* border-top: 2px solid #4a89dc !important; */
}


.ikedc {
  background-color: #ff6347 !important;
}

.ibedc {
  background-color: #365873 !important;
}

.jed {
    background-color: #fe7704 !important;
}

.aedc {
    background-color: #607d8b !important;
}

.kaedco {
    background-color: #2d8426 !important;
}

.ekedc {
    background-color: #26156b !important;
}

.kedco {
    background-color: #00007e !important;
}
.eedc {
    background-color: #db261d !important;
}

@media only screen and (max-width: 768px) {
    .bills_col_width {
      width: 105px;
        }
    }

    @media only screen and (min-width: 768px) {
    .card_image_bg {
      background-size: contain;
    }
}
